import React from "react";
import wodkaImage from "../../../assets/wodka-agency.svg";
import NavigationItems from "../NavigationItems/NavigationItems";

const NavigationSide = ({ activeItem, menuItems, logout }) => {
  return (
    <div className="border-r border-gray-200 px-3">
      <img className="py-9 px-3" src={wodkaImage} />
      <NavigationItems
        activeItem={activeItem}
        menuItems={menuItems}
        logout={logout}
      />
    </div>
  );
};

export default NavigationSide;
