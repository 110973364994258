import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./NavigationItem.module.scss";

const NavigationItem = (props) => {
  return (
    <NavLink
      className={styles.nav}
      to={props.link}
      exact={props.exact}
      activeClassName={styles.active}
    >
      {props.children}
    </NavLink>
  );
};

export default NavigationItem;
