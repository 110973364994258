import React from "react";
import NavigationItem from "./NavigationItem/NavigationItem";

const NavigationItems = (props) => {
  let listItems = null;
  if (props.menuItems) {
    listItems = props.menuItems.map((item) => (
      <NavigationItem icon={item.icon} key={item.id} link={item.url}>
        {item.icon ? <span className="mr-4">{item.icon}</span> : ""}
        <span>{item.name}</span>
      </NavigationItem>
    ));
  }
  return <ul className="flex flex-col">{listItems}</ul>;
};

export default NavigationItems;
