import React from "react";
import NavigationSide from "../../components/Navigation/NavigationSide/NavigationSide";
import styles from "./Layout.module.scss";
import { HomeOutline, UserGroupOutline } from "heroicons-react";

const Layout = (props) => {
  let content = props.children;
  let menuItems = [];
  if (props.isAdmin) {
    menuItems = [
      {
        id: "prjlist",
        url: "/projects",
        icon: <HomeOutline size={30} />,
        name: "Progetti",
      },
      {
        id: "userlist",
        url: "/users",
        icon: <UserGroupOutline size={30} />,
        name: "Team",
      },
    ];
  }

  return (
    <div className="min-vh-100">
      {props.isAuth ? (
        <>
          {/*<Toolbar menuItems={menuItems} logout={props.onLogout}/>*/}
          <div className="mx-auto grid grid-cols-6">
            <NavigationSide menuItems={menuItems} logout={props.onLogout} />
            <div className="col-span-5">{content}</div>
          </div>
        </>
      ) : (
        <div className="container mx-auto py-3">{content}</div>
      )}
    </div>
  );
};

export default Layout;
