import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDuZYHk6UnJ6ahK5_ylWb_s4NAYn9N1Rf0",
  authDomain: "wodka-tracker.firebaseapp.com",
  databaseURL: "https://wodka-tracker.firebaseio.com",
  projectId: "wodka-tracker",
  storageBucket: "wodka-tracker.appspot.com",
  messagingSenderId: "718620701637",
  appId: "1:718620701637:web:f4b74adf54afb0bf42d1ac",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
