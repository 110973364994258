import React, { Suspense, lazy } from "react";
import "./styles/core.css";
// import * as actions from './store/actions/index';
import { connect } from "react-redux";
import Layout from "./containers/Layout/Layout";
import { autoLogin } from "./store/slices/auth";
import { Redirect, Route, Switch } from "react-router-dom";
import useConstructor from "./hooks/useConstructor";
import "react-datepicker/dist/react-datepicker.css";

const App = (props) => {
  useConstructor(() => {
    props.onTryAutoSignup();
  });

  const ViewProject = lazy(() => import("./containers/Project/ViewProject"));
  const ListProject = lazy(() => import("./containers/Project/ListProject"));
  const UserList = lazy(() => import("./containers/User/UserList"));
  const UserView = lazy(() => import("./containers/User/UserView/UserView"));
  const Error404 = lazy(() => import("./containers/Error404/Error404"));
  const UserMyProfile = lazy(() =>
    import("./containers/User/UserMyProfile/UserMyProfile")
  );
  const Login = lazy(() => import("./containers/Login"));

  let routes;

  routes = (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path="/my-profile" component={UserMyProfile} />
        <Route path="/" exact component={Login} />
        <Route path="/" component={Error404} />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );

  if (props.isAuthenticated && props.isAdmin) {
    routes = (
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path="/projects/:id" component={ViewProject} />
          <Route path="/projects" exact component={ListProject} />
          <Route path="/users" exact component={UserList} />
          <Route path="/users/:id" component={UserView} />
          <Route path="/" exact component={Login} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    );
  }

  if (props.authLoading && !props.isAuthenticated) {
    routes = "Loading...";
  }

  if (!props.isAuthenticated) {
    routes = (
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path="/" exact component={Login} />
        </Switch>
      </Suspense>
    );
  }

  return (
    <>
      <Layout isAdmin={props.isAdmin} isAuth={props.isAuthenticated}>
        {routes}
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    isAdmin: state.auth.role === "admin",
    authLoading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(autoLogin()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
