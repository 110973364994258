import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { auth, firestore } from "../../Firebase";

const initialState = {
  token: null,
  userId: null,
  error: null,
  violations: null,
  needValidation: false,
  loading: false,
  errorValidation: null,
  violationsValidation: null,
  loadingValidation: false,
  authRedirectPath: "/",
};

export const login = createAsyncThunk("login", async (data) =>
  auth
    .signInWithEmailAndPassword(data.email, data.password)
    .then((user) => {
      return user.user.getIdTokenResult().then(async (idTokenResult) => {
        const role = await firestore
          .collection("users")
          .doc(idTokenResult.claims.user_id)
          .get()
          .then((docRef) => {
            return docRef.data().role;
          });
        return {
          token: idTokenResult.token,
          userId: idTokenResult.claims.user_id,
          role: role,
        };
      });
    })
    .catch((error) => {
      console.log(error);
    })
);

export const autoLogin = createAsyncThunk(
  "autoLogin",
  async (data) =>
    new Promise((resolve) => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          const token = user.getIdTokenResult().then(async (idTokenResult) => {
            const role = await firestore
              .collection("users")
              .doc(idTokenResult.claims.user_id)
              .get()
              .then((docRef) => {
                return docRef.data().role;
              });
            return {
              token: idTokenResult.token,
              userId: idTokenResult.claims.user_id,
              role: role,
            };
          });
          return resolve(token);
        }
        return () => new Promise(() => resolve());
      });
    })
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [autoLogin.pending]: (state, action) => {
      //state.loading = true;
    },

    [autoLogin.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.token = action.payload.token;
        state.userId = action.payload.userId;
        state.role = action.payload.role;
        if (action.payload.role === "admin") {
          state.authRedirectPath = "/projects";
        } else {
          state.authRedirectPath = "/my-profile";
        }
      }
    },
    [autoLogin.rejected]: (state, action) => {},

    [login.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.token = action.payload.token;
        state.userId = action.payload.userId;
        state.role = action.payload.role;
        if (action.payload.role === "admin") {
          state.authRedirectPath = "/projects";
        } else {
          state.authRedirectPath = "/projects";
        }
      }
    },
    [login.rejected]: (state, action) => {},
  },
});

export default authSlice.reducer;
